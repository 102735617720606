import { useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { removeItemFromLocalStorage, saveItemWithExpiryToLocalStorage } from '@netfront/common-library';
import {
  IGeladaProject,
  useBackofficeSiteUrls,
  useDomain,
  useGetGeladaProject,
  useGetGeladaOrganisationByKey,
  IGeladaOrganisation,
  useProtectedRoute,
} from '@netfront/gelada-identity-library';
import { Spinner } from '@netfront/ui-library';
import { useToast } from 'hooks';
import { useRouter } from 'next/router';

import CachingEntitiesContext from './CachingEntitiesContext';
import { CachingEntitiesContextProps } from './CachingEntitiesContext.interfaces';

export function CachingEntitiesProvider({ children }: Readonly<CachingEntitiesContextProps>) {
  const { getBaseUrl } = useBackofficeSiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_BACK_OFFICE_LOCAL_PORT,
  });
  const { isDomainReady } = useDomain();
  const { isAuthenticated } = useProtectedRoute({
    environment: process.env.REACT_APP_ENVIRONMENT,
    identitySitePort: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
    isRedirectingUnauthenticatedUser: false,
  });
  const {
    query: { projectId: queryProjectId, organisationKey: queryOrganisationKey },
  } = useRouter();
  const { handleToastError } = useToast();

  const [projectId, setProjectId] = useState<string>('');
  const [organisationKey, setOrganisationKey] = useState<string>('');
  const [cmsBaseUrl, setCmsBaseUrl] = useState<string>('');
  const [organisation, setOrganisation] = useState<IGeladaOrganisation>();
  const [organisationExpiry, setOrganisationExpiry] = useState<number>();
  const [project, setProject] = useState<IGeladaProject>();
  const [projectExpiry, setProjectExpiry] = useState<number>();

  const { handleGetGeladaProject, isLoading: isGetGeladaProjectLoading } = useGetGeladaProject({
    fetchPolicy: 'cache-first',
    onCompleted: ({ geladaProject }) => {
      saveItemWithExpiryToLocalStorage('project', JSON.stringify(geladaProject), {
        currentTimeValueInMilliseconds: new Date().getTime(),
        expiryTime: {
          unit: 'hours',
          value: 1,
        },
      });

      setProject(geladaProject);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleGetGeladaOrganisationByKey, isLoading: isGetGeladaOrganisationLoading } = useGetGeladaOrganisationByKey({
    fetchPolicy: 'cache-first',
    onCompleted: ({ geladaOrganisation }) => {
      saveItemWithExpiryToLocalStorage('organisation', JSON.stringify(geladaOrganisation), {
        currentTimeValueInMilliseconds: new Date().getTime(),
        expiryTime: {
          unit: 'hours',
          value: 1,
        },
      });

      setOrganisation(geladaOrganisation);
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  useEffect(() => {
    if (!(projectId && isAuthenticated)) {
      return;
    }

    if (projectId !== project?.id) {
      removeItemFromLocalStorage('project');

      void handleGetGeladaProject({
        projectId: String(projectId),
        shouldIncludeProjectLogo: true,
        shouldIncludeProjectSettings: true,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, project]);

  useEffect(() => {
    if (!(organisationKey && isAuthenticated)) {
      return;
    }

    if (Number(organisationKey) !== organisation?.id) {
      removeItemFromLocalStorage('organisation');

      void handleGetGeladaOrganisationByKey({
        organisationKey: String(organisationKey),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisation, organisationKey]);

  useEffect(() => {
    const projectJSON = JSON.parse(String(window.localStorage.getItem('project')));

    if (!projectJSON) {
      return;
    }

    setProject(JSON.parse(String(projectJSON.value)) as IGeladaProject);
    setProjectExpiry(Number(projectJSON.expiry));
  }, []);

  useEffect(() => {
    if (!projectExpiry) {
      return;
    }

    if (new Date().getTime() < projectExpiry) {
      return;
    }

    removeItemFromLocalStorage('project');
  }, [projectExpiry]);

  useEffect(() => {
    const organisationJSON = JSON.parse(String(window.localStorage.getItem('organisation')));

    if (!organisationJSON) {
      return;
    }

    setOrganisation(JSON.parse(String(organisationJSON.value)) as IGeladaOrganisation);
    setOrganisationExpiry(Number(organisationJSON.expiry));
  }, []);

  useEffect(() => {
    if (!organisationExpiry) {
      return;
    }

    if (new Date().getTime() < organisationExpiry) {
      return;
    }

    removeItemFromLocalStorage('organisation');
  }, [organisationExpiry]);

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    setCmsBaseUrl(getBaseUrl());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  useEffect(() => {
    setOrganisationKey(queryOrganisationKey as string);
  }, [queryOrganisationKey]);

  const isLoading = isGetGeladaProjectLoading ?? isGetGeladaOrganisationLoading;

  return (
    <CachingEntitiesContext.Provider
      value={{
        cmsBaseUrl,
        organisation,
        project,
      }}
    >
      {isLoading ? <Spinner isLoading={true} /> : children}
    </CachingEntitiesContext.Provider>
  );
}
