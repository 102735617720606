import { createContext } from 'react';

import { IPlanContext } from './PlanContext.interfaces';


export const PlanContext = createContext<IPlanContext>({
  activePlan: undefined,
  downgradeRequest: undefined,
  isFreePlan: true,
  planName: undefined,
  planScanAmount: 100,
  remainingScans: 100,
  setPlanConfig: () => undefined,
});

// eslint-disable-next-line import/no-default-export
export default PlanContext;
