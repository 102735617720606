import { useEffect, useState } from 'react';

import { ISelectedPlan, QR_CODE_PRODUCT_ID } from 'components';
import { IDBDowngradeRequest, useGetMuriquiProject, useToast, useGetActivePlans } from 'hooks';


import PlanContext from './PlanContext';
import { PlanContextProps } from './PlanContext.interfaces';


export function PlanProvider({ children }: PlanContextProps) {
  const { handleToastError } = useToast();

  const [projectId, setProjectId] = useState<string | undefined>();
  const [planName, setPlanName] = useState<string | undefined>();
  const [isFreePlan, setIsFreePlan] = useState<boolean>(true);
  const [activePlan, setActivePlan] = useState<ISelectedPlan>();
  const [currentRemainingScans, setCurrentRemainingScans] = useState<number>(100);
  const [planScanAmount, setPlanScanAmount] = useState<number>(100);
  const [downgradeRequest, setDowngradeRequest] = useState<IDBDowngradeRequest | undefined>();

  const { handleGetMuriquiProject } = useGetMuriquiProject({
    fetchPolicy: 'no-cache',
    onCompleted: ({ project }) => {
      const {
        customerDetail: { subscriptions },
      } = project;
      if (!subscriptions || subscriptions.length === 0) return;
      const [subscription] = subscriptions;
      const { downgradeRequest: request } = subscription;
      if (!request) return;
      setDowngradeRequest(request);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleGetActivePlans } = useGetActivePlans({
    onCompleted: ({ plans: activePlans = [] }) => {
      setActivePlan({
        plan: activePlans.find(({ productId }) => productId === QR_CODE_PRODUCT_ID),
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const setPlanConfig = ({ projectId: geladaProjectId, remainingScans}: { projectId: string; remainingScans: number}) => {
    setProjectId(geladaProjectId);
    setCurrentRemainingScans(remainingScans);
  };

  useEffect(() => {
    const { plan } = activePlan ?? {};
    if (!activePlan || !plan) {
      setIsFreePlan(true);
      setPlanScanAmount(100);
      setPlanName('Free');

    } else {
      const { name, value } = plan;
      setIsFreePlan(name.toLocaleLowerCase() === 'free');
      setPlanScanAmount(value);
      setPlanName(name);
    }
  }, [activePlan]);



  useEffect(() => {
    if (!projectId) return;

    void handleGetMuriquiProject({
      projectId,
    });

    void handleGetActivePlans({
      projectId,
      product: 'QUICKCODES',
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);





  return (
    <PlanContext.Provider
      value={{
        activePlan,
        downgradeRequest,
        isFreePlan,
        planName,
        planScanAmount,
        remainingScans: currentRemainingScans,
        setPlanConfig,
      }}
    >
      {children}
    </PlanContext.Provider>
  );
}
