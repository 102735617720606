import { QrCodeLevelDescriptiveType, QrCodeLevelType } from '../types';

const DEFAULT_PAGE_SIZE = 10;

const PUBLISHED = 'PUBLISHED';

const QUICKCODES_COOKIE_CONSENT_MESSAGE = 'We use cookies to make your experience on this website better';

const STATIC_PAGE_REGENERATION_TIME_IN_SECONDS = 60;

const QR_CODE_LEVEL_TYPES: Record<QrCodeLevelDescriptiveType, QrCodeLevelType> = {
  low: 'L',
  medium: 'M',
  default: 'Q',
  high: 'H',
};

export { DEFAULT_PAGE_SIZE, PUBLISHED, QUICKCODES_COOKIE_CONSENT_MESSAGE, STATIC_PAGE_REGENERATION_TIME_IN_SECONDS, QR_CODE_LEVEL_TYPES };
