import { IOption } from '@netfront/ui-library';

export const DEFAULT_QR_CODE_PLAN_ID = 1;

export const PDF_PRODUCT_ID = 3;
export const QR_CODE_PRODUCT_ID = 1;

export const UNLIMITED_QR_CODE_PLAN_ID = 6;

export const CURRENCY_OPTIONS: IOption[] = [
  {
    id: 1,
    name: 'AUD',
    value: 'AUD',
  },
  {
    id: 2,
    name: 'USD',
    value: 'USD',
  },
  {
    id: 3,
    name: 'EUR',
    value: 'EUR',
  },
  {
    id: 4,
    name: 'YEN',
    value: 'YEN',
  },
  {
    id: 5,
    name: 'YUAN',
    value: 'YUAN',
  },
  {
    id: 6,
    name: 'GBP',
    value: 'GBP',
  },
];
