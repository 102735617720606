/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext } from 'react';

export interface IPermissionContext {
  getPermission: (id: string) => 'LIMITED' | 'NONE' | 'MANAGE_USERS' | 'EDIT' | 'READ' | 'LIMITED' | undefined;
  hasPermission: (id: string, permission: 'NONE' | 'MANAGE_USERS' | 'EDIT' | 'READ' | 'LIMITED') => boolean;
}

export const PermissionContext = createContext<IPermissionContext>({
  getPermission: (): 'LIMITED' | 'NONE' | 'MANAGE_USERS' | 'EDIT' | 'READ' | 'LIMITED' | undefined => undefined,
  hasPermission: (id: string, permission: 'LIMITED' | 'NONE' | 'MANAGE_USERS' | 'EDIT' | 'READ' | 'LIMITED'): boolean => false,
});

// eslint-disable-next-line import/no-default-export
export default PermissionContext;
